import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../redux/store"
import messages from "../assets/messages.json"
import { setPage } from "../redux/reducers/indexSlice"

const Footer: React.FC = () => {
  const language = useSelector((state: RootState) => state.inner.language)
  const dispatch = useDispatch()
  function navigate(number) {
    dispatch(setPage(number))
  }
  const data = useStaticQuery(graphql`
    query {
      customAdminServiceblockAdminServiceblockResourceIt {
        img_big
        img_little
        link_su_misura
        link_contract
        body
      }
      customAdminServiceblockAdminServiceblockResourceEn {
        img_big
        img_little
        link_su_misura
        link_contract
        body
      }
      customAdminContactsAdminContactsResourceEn {
        ccp
        email
        fb
        ig
        img
        via
        tw
        tel
        stato
        mob
      }
      customAdminContactsAdminContactsResourceIt {
        ccp
        email
        fb
        ig
        img
        mob
        stato
        tel
        tw
        via
      }
      drupal {
        taxonomyTermQuery(limit: 1000) {
          it: entities(language: IT) {
            ... on Drupal_TaxonomyTermShowroomCategorie {
              name
              tid
            }
          }
          en: entities(language: EN) {
            ... on Drupal_TaxonomyTermShowroomCategorie {
              name
              tid
            }
          }
        }
      }
    }
  `)

  return (
    <footer className="c-footer">
      <div>
        <div>
          <img src={require('../assets/img/logo-white.png')} alt="logo" />
        </div>
        <ul>
          <li>
            <p>{messages[language.toLowerCase()].address}</p>
            <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].via}</p>
            <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].ccp}</p>
            <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].stato}</p>
          </li>
          <li>
            <p>{messages[language.toLowerCase()].contacts}</p>
            <a href={`mailto:${data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].email}`}>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].email}</a>
            <a href={`tel:${data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].tel}`}>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].tel}</a>
            <a href={`tel:${data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].mob}`}>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].mob}</a>
          </li>
          <li>
            <p>Social</p>
            <a href={"//" + data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].fb}>Facebook</a>
            <a href={"//" + data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].ig}>Instagram</a>
            <a href={"//" + data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].tw}>Twitter</a>
          </li>
        </ul>
      </div>
      <nav className="c-footer__nav">
        <ul>
          <li>
            <Link to="/chi-siamo" onClick={() => navigate(1)}>{messages[language.toLowerCase()].aboutUs}</Link>
          </li>
          <li>
            <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceIt.link_contract}>{messages[language.toLowerCase()].contractServices}</a>
          </li>
          <li>
            <a href={'//' + data.customAdminServiceblockAdminServiceblockResourceIt.link_su_misura}>{messages[language.toLowerCase()].tailoredServices}</a>
          </li>
          {/* {
            data.drupal.taxonomyTermQuery.it.map((tax, index) => {
              return (
                <li key={`showroom-${tax.tid}`}>
                  <Link onClick={() => navigate(index + 5)} to={'/' + tax.name.toLowerCase().replace(/\s+/g, '-')}>{data.drupal.taxonomyTermQuery[language.toLowerCase()][index].name}</Link>
                </li>
              )
            })
          }
          <li>
            <Link to="/promozioni" onClick={() => navigate(2)}>{messages[language.toLowerCase()].ourPromotions}</Link>
          </li> */}
          <li>
            <Link to="/contatti" onClick={() => navigate(4)}>{messages[language.toLowerCase()].contacts}</Link>
          </li>
        </ul>
      </nav>
      <div>Copyright ©2020 - Dandy Home</div>
    </footer>
  )
}

export default Footer
