import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { setPage } from "../redux/reducers/indexSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux/store"
import messages from "../assets/messages.json"

const Menu: React.FC<any> = ({ visible, closeMenu }) => {
  const language = useSelector((state: RootState) => state.inner.language)
  const page = useSelector((state: RootState) => state.inner.page)
  const dispatch = useDispatch()

  function navigate(number) {
    dispatch(setPage(number))
    closeMenu()
  }

  const data = useStaticQuery(graphql`
    query {
      drupal {
        taxonomyTermQuery(limit: 1000) {
          it: entities(language: IT) {
            ... on Drupal_TaxonomyTermShowroomCategorie {
              name
              tid
            }
          }
          en: entities(language: EN) {
            ... on Drupal_TaxonomyTermShowroomCategorie {
              name
              tid
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`c-menu ${visible ? "open" : ""}`}>
      <div>
        <div>
          <button className="o-btn(exit)" onClick={() => closeMenu()}></button>
        </div>
        <div>
          <Link to="/">
            <img src={require('../assets/img/logo-white.png')} alt="logo" width="160" />
          </Link>
        </div>
      </div>
      <div>
        <ul>
          <li>
            <a href="/"><img src={require('../assets/img/facebook-white.svg')} alt="facebook" /></a>
          </li>
          <li>
            <a href="/"><img src={require('../assets/img/instagram-white.svg')} alt="instagram" /></a>
          </li>
          <li>
            <a href="/"><img src={require('../assets/img/twitter-white.svg')} alt="twitter" /></a>
          </li>
        </ul>
        <nav>
          <ul>
            <li className={page === 0 ? 'selected' : ''}>
              <Link to="/" onClick={() => navigate(0)}>Homepage</Link>
            </li>
            <li className={page === 1 ? 'selected' : ''}>
              <Link to="/chi-siamo" onClick={() => navigate(1)}>{messages[language.toLowerCase()].aboutUs}</Link>
            </li>
            {/* {
              data.drupal.taxonomyTermQuery.it.map((tax, index) => {
                return (
                  <li key={`showroom-${tax.tid}`} className={page === (index + 5) ? 'selected' : ''}>
                    <Link onClick={() => navigate((index + 5))} to={'/' + tax.name.toLowerCase().replace(/\s+/g, '-')}>{data.drupal.taxonomyTermQuery[language.toLowerCase()][index].name}</Link>
                  </li>
                )
              })
            }
            <li className={page === 2 ? 'selected' : ''}>
              <Link to="/promozioni" onClick={() => navigate(2)}>{messages[language.toLowerCase()].ourPromotions}</Link>
            </li> */}
            <li className={page === 3 ? 'selected' : ''}>
              <Link to="/marchi" onClick={() => navigate(3)}>{messages[language.toLowerCase()].ourBrands}</Link>
            </li>
            <li className={page === 4 ? 'selected' : ''}>
              <Link to="/contatti" onClick={() => navigate(4)}>{messages[language.toLowerCase()].contacts}</Link>
            </li>
            <li className='small l-mt(6)'>
              <Link to="/">Credits</Link>
            </li>
            <li className='small'>
              <Link to="/">Privacy</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Menu
