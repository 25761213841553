import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import Menu from "./menu"
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from "../redux/reducers/indexSlice"
import { RootState } from "../redux/store"

const Header: React.FC = () => {
  const [logoWidth, setLogoWidth] = useState(160)
  const [openMenu, setOpenMenu] = useState(false)
  const selectedLanguage = useSelector((state: RootState) => state.inner.language)
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        setLogoWidth(80)
      }
      if (window.scrollY < 10) {
        setLogoWidth(160)
      }
    })
  })

  return (
    <>
      <header className="c-header">
        <div>
          <button className="o-hamburger" onClick={() => setOpenMenu(true)}>
            <span></span>
          </button>
        </div>
        <div>
          <Link to="/">
            <img src={require('../assets/img/logo.png')} alt="logo" width={logoWidth} />
          </Link>
        </div>
        <div>
          <button className={selectedLanguage === "IT" ? "isActive" : ""} onClick={() => dispatch(setLanguage('IT'))}>IT</button>
          <button className={selectedLanguage === "EN" ? "isActive" : ""} onClick={() => dispatch(setLanguage('EN'))}>EN</button>
        </div>
      </header>
      <Menu visible={openMenu} closeMenu={() => setOpenMenu(false)} />
    </>
  )
}

export default Header
