import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Rellax from "rellax"

import Header from "./header"
import Footer from "./footer"

const Layout: React.FC<any> = ({ children }) => {
  useEffect(() => {
    const slideInEl = document.getElementsByClassName('slide-in')
    for (let el of slideInEl) {
      const bound = el.getBoundingClientRect()
      if (bound.top <= (window.innerHeight - bound.height / 2) && bound.top >= -(window.innerHeight - bound.height / 2)) {
        el.classList.add('is-visible')
      }
    }
    window.addEventListener('scroll', () => {
      for (let el of slideInEl) {
        const bound = el.getBoundingClientRect()
        if (bound.top <= (window.innerHeight - bound.height / 2) && bound.top >= -(window.innerHeight - bound.height / 2)) {
          el.classList.add('is-visible')
        }
      }
    })
    const rellax = Rellax('.rellax')

    return rellax.refresh()
  }, [])

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
